import React from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import Button from "../form/Button"
// Having to import theme separate because theme outside global scope
import theme from "../../utils/theme"
import SadPeach from "../../images/sad-peach.png"

const Inner = styled.div`
  background: ${props => props.theme.apricot};
  border-radius: ${props => props.theme.radius.xl};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 24px;
  text-align: center;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr min-content;
  gap: 16px;
  min-width: 300px;
  min-height: 320px;
  animation: ${props =>
    props.fadeOut ? "fadeOut 0.4s ease-in-out" : "fadeIn 0.4s ease-in-out"};
  will-change: opacity, transform;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.4) translate(0, -10px) rotate(4deg);
    }
    to {
      opacity: 1;
      transform: scale(1) translate(0, 0) rotate(0deg);
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: scale(1) translate(0, 0) rotate(0deg);
    }
    to {
      opacity: 0;
      transform: scale(0.4) translate(0, -10px) rotate(4deg);
    }
  }
`
const Content = styled.div`
  overflow: auto;
  display: flex;
  justify-content: center;
  flex-flow: column;
`
const Title = styled.h2`
  font-family: "Alt Goth", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.shade[100]};
  font-size: 2.6rem;
  margin: 0 0 auto;
`
const Text = styled.p`
  margin-bottom: auto;
  color: ${props => props.theme.shade[100]};
`
const Peach = styled.img`
  animation: spinIn 0.6s ease-in-out;
  margin: 0 0 -64px 0;
  will-change: opacity, transform;
  max-width: 240px;
  @keyframes spinIn {
    from {
      opacity: 0;
      transform: scale(0.4) translate(0, -10px) rotate(-20deg);
    }
    to {
      opacity: 1;
      transform: scale(1) translate(0, 0) rotate(0deg);
    }
  }
`
const Tag = styled.h2`
  font-family: "Alt Goth", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.shade[100]};
  font-size: 5rem;
  position: relative;
  animation: pop 0.6s ease-in-out forwards;
  z-index: 1;
  margin: -32px 0 0 0;
  will-change: opacity, transform;
  @keyframes pop {
    from {
      opacity: 0;
      transform: scale(0.4) translate(0, 20px);
    }
    to {
      opacity: 1;
      transform: scale(1) translate(0, 0) rotate(4deg);
    }
  }
`
const EmojiContainer = styled.div`
  border-radius: ${props => props.theme.radius.xl};
  background: ${props => props.theme.apricot};
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  animation: fadeOut 1s ease-in-out forwards;
  animation-delay: 0.8s;
  z-index: 3;
  pointer-events: none;
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      user-select: none;
      pointer-events: none;
    }
  }
`
const Grid = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 8px;
`

export default function ModalIncorrect({
  title,
  text,
  textColor,
  onClick,
  onClickSkip,
  modalIsOpen,
  closeModal,
  fadeOut,
  chances,
}) {
  return (
    <div>
      <Global
        styles={css`
          .CustomPortal {
            z-index: 1;
          }
          .CustomOverlay {
            z-index: 1;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 24px;
          }
          .CustomContent {
            z-index: 1;
            margin: auto;
            border-radius: ${theme.radius.xl};
            outline: none;
          }
        `}
        theme={theme}
      />
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={"CustomContent"}
        portalClassName={"CustomPortal"}
        overlayClassName={"CustomOverlay"}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
      >
        <Inner fadeOut={fadeOut}>
          <EmojiContainer>
            <Peach src={SadPeach} alt="A Sad Peach" />
            <Tag>Ooops!</Tag>
          </EmojiContainer>
          <Content>
            {title && <Title textColor={textColor}>{title}</Title>}
            {text && <Text textColor={textColor}>{text}</Text>}
          </Content>
          <Grid>
            <Button
              buttonStyle={"ghost"}
              backgroundColor={props => props.theme.shade[100]}
              onClick={onClickSkip}
            >
              Skip
            </Button>
            {chances !== 0 && (
              <Button
                buttonStyle={"inverted"}
                backgroundColor={props => props.theme.apricot}
                onClick={onClick}
              >
                Try Again
              </Button>
            )}
          </Grid>
        </Inner>
      </Modal>
    </div>
  )
}

ModalIncorrect.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  fadeOut: PropTypes.bool,
}

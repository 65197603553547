import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

const Container = styled.button`
  background-color: ${props => props.theme.apricot};
  width: 12px;
  height: 12px;
  position: relative;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 50%;
  position: absolute;
  border: none;
  padding: 0;
  top: ${props => props.top};
  left: ${props => props.left};
`
const Sonar = styled.div`
  position: absolute;
  top: 0;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: 1px solid ${props => props.theme.apricot};
  animation: pulse 6s linear;
  animation-iteration-count: infinite;
  @keyframes pulse {
    from {
      opacity: 1;
      transform: scale(0.8);
    }
    to {
      opacity: 0;
      transform: scale(3);
    }
  }
  &:nth-of-type(1) {
    animation-delay: -2s;
  }
  &:nth-of-type(2) {
    animation-delay: -4s;
  }
  &:nth-of-type(3) {
    animation-delay: -6s;
  }
`

export default function HostSpotBeacon({ top, left, onClick, id }) {
  return (
    <Container top={top} left={left} onClick={onClick} id={id}>
      <Sonar />
      <Sonar />
      <Sonar />
    </Container>
  )
}

HostSpotBeacon.propTypes = {
  top: PropTypes.string,
  left: PropTypes.string,
  onClick: PropTypes.func,
}

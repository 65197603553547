import { navigate } from "gatsby"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import ImageHotSpot from "../../components/block/ImageHotSpot"
import ModalCorrect from "../../components/block/ModalCorrect"
import ModalIncorrect from "../../components/block/ModalIncorrect"
import Layout from "../../components/global/layout"
import { urls } from "../../data/urls"
import useFirebase from "../../hooks/useFirebase"
import { addFbDocumentId, addQuiz1Data } from "../../state/app"

const QuestionData = [
  {
    questionText: "Where’s the Mons pubis?",
    correctHotSpotId: "Mons pubis",
    response:
      "The fatty mound of flesh that covers the pubic bone, and where pubic hair grows after puberty. Some people choose to trim or remove some or all of their pubic hair, while others prefer their bush all natural.",
  },
  {
    questionText: "Where’s the Urethra?",
    correctHotSpotId: "Urethra",
    response:
      "Where your wee (or urine) comes out. The urethral opening is small and hard to see. Some women worry about the urethra being penetrated during sex instead of the vagina, but it’s far too small for that to happen.",
  },
  {
    questionText: "Where’s the Outer Labia?",
    correctHotSpotId: "Outer labia",
    response:
      "The labia majora as they're known, are the fleshy folds of skin that protect the external genital organs. Pubic hair grows here too.",
  },
  {
    questionText: "Where’s the Inner labia ?",
    correctHotSpotId: "Inner labia",
    response:
      "Also called the labia minora, you'll find these inside the outer labia. They have no pubic hair and they come in all shapes, sizes and colours. Sometimes one side is longer than the other. All completely normal. ",
  },
  {
    questionText: "Where’s the Clitoris?",
    correctHotSpotId: "Clitoris",
    response:
      "The clitoris is the only organ in the human body designed purely for pleasure which is why it's one of our faves. It's packed full of nerves so it can be extremely sensitive to the touch. The visible part is a small pea-shaped bump with a hood of skin covering it, but most of the clitoris is internal and can't be seen. ",
  },
  {
    questionText: "Where’s the Vagina?",
    correctHotSpotId: "Vagina",
    response:
      "The vagina is the passage that connects the vulva and the uterus. It's pretty amazing: it can expand lengthways and widthways so brings babies into the world, it's where blood leaves the body during your period, too. It is also where fingers, tongues, sex toys or a penis can be inserted during sex, so it's super fun, really.",
  },
  {
    questionText: "Where’s the Perenium?",
    correctHotSpotId: "Perenium",
    response:
      "The space between your vulva and your anus (your bottom). There are nerve endings in this area for all genders, so it can feel good to stimulate during sexual activity.",
  },
  {
    questionText: "Where’s the Hymen?",
    correctHotSpotId: "Hymen",
    response:
      "You can't actually see the hymen but it's a ring of thin skin which covers part of the opening of the vagina. When you have sex for the first time there can sometimes be bleeding afterwards. The hymen can also stretch from using tampons and playing sport. No one knows why we have a hymen so don't panic if yours doesn't 'wait' for sex to disappear.",
  },
]

export default function HotSpotPage() {
  const firebase = useFirebase()
  const dispatch = useDispatch()

  const documentReference = useSelector(state => state.app.firebaseDocumentId)

  const [correctModalIsOpen, setIsCorrectOpen] = useState(false)
  const [correctFadeOut, setCorrectFadeOut] = useState(false)

  const [incorrectModalIsOpen, setIsIncorrectOpen] = useState(false)
  const [incorrectFadeOut, setIncorrectFadeOut] = useState(false)

  const [currentQuestionIndex, setcurrentQuestionIndex] = useState(0)
  const initialChances = 1000
  const [chances, setChances] = useState(initialChances)

  const [answers, setAnswers] = useState([])

  const initialTitle = "Oops"
  const initialBodyText =
    "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eum possimus iure dolore nostrum deserunt numquam molestiae quidem quis ullam inventore aliquid laborum, sapiente, expedita esse molestias unde quisquam quod? Repellendus."

  const [incorrectModalTitleText] = useState(initialTitle)
  const [incorrectModalBodyText, setincorrectModalBodyText] = useState(
    initialBodyText
  )

  const [correctModalBodyText, setcorrectModalBodyText] = useState(
    initialBodyText
  )

  //   Correct Modal Logic
  function openCorrectModal() {
    setcorrectModalBodyText(QuestionData[currentQuestionIndex].response)
    setCorrectFadeOut(false)
    setIsCorrectOpen(true)
  }

  function closeCorrectModal() {
    setCorrectFadeOut(true)
    moveToNextQuestion(true)
    setTimeout(() => {
      setIsCorrectOpen(false)
    }, 399)
  }

  //   Incorrect Modal Logic
  // If they get the answer wrong x times, they will move on automatically
  function openIncorrectModal() {
    setIncorrectFadeOut(false)
    setIsIncorrectOpen(true)
  }

  function closeIncorrectModal() {
    setIncorrectFadeOut(true)
    setTimeout(() => {
      setIsIncorrectOpen(false)
    }, 399)
  }

  function moveToNextQuestion(answer) {
    if (incorrectModalIsOpen) closeIncorrectModal()

    let newAnswers = answers
    if (answer) {
      newAnswers.push(true)
    } else {
      newAnswers.push(false)
    }
    setAnswers(newAnswers)

    const index = currentQuestionIndex + 1
    if (index < QuestionData.length) {
      setcurrentQuestionIndex(index)
      setChances(initialChances)
    } else {
      finishQuiz()
    }
  }

  function openModal(id) {
    if (id === QuestionData[currentQuestionIndex].correctHotSpotId) {
      openCorrectModal()
    } else {
      const count = chances - 1
      setChances(count)

      if (count < 0) {
        moveToNextQuestion()
      } else {
        setincorrectModalBodyText(`That's your ${id}.`)

        openIncorrectModal()
      }
    }
  }

  // Need a submit button at the end of the quiz
  async function finishQuiz() {
    // TODO Add loading modal
    const data = {
      lastUpdated: Date.now(),
      quiz1: {
        dateCompleted: Date.now(),
        answers: answers,
      },
    }

    dispatch(addQuiz1Data(answers))

    let docRef = documentReference

    if (!documentReference) {
      try {
        const doc = await firebase
          .firestore()
          .collection("userData")
          .add({ lastUpdated: Date.now() })
        docRef = doc.id
      } catch (error) {
        console.error("Error adding document: ", error)
        toast.error("An error has occured.", {
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: false,
          closeOnClick: true,
          autoClose: 2500,
        })
      }
    }

    dispatch(addFbDocumentId(docRef))

    try {
      await firebase
        .firestore()
        .collection("userData")
        .doc(docRef)
        .set(data, { merge: true })
    } catch (error) {
      console.error("Error adding document: ", error)
      toast.error("An error has occured.", {
        position: toast.POSITION.BOTTOM_CENTER,
        hideProgressBar: false,
        closeOnClick: true,
        autoClose: 2500,
      })
    } finally {
      navigate(urls.KYV.SUMMARY)
    }
  }

  return (
    <Layout title={`Question ${currentQuestionIndex + 1}`}>
      <ModalIncorrect
        onClick={closeIncorrectModal}
        onClickSkip={() => moveToNextQuestion(false)}
        modalIsOpen={incorrectModalIsOpen}
        fadeOut={incorrectFadeOut}
        closeModal={closeIncorrectModal}
        chances={chances}
        title={incorrectModalTitleText}
        text={incorrectModalBodyText}
        buttonText="Next Question"
      />
      <ModalCorrect
        onClick={closeCorrectModal}
        modalIsOpen={correctModalIsOpen}
        fadeOut={correctFadeOut}
        closeModal={closeCorrectModal}
        text={correctModalBodyText}
        tagText="Yeah!"
        buttonText={
          currentQuestionIndex < QuestionData.length - 1
            ? "Next Question"
            : "All Finished"
        }
      />
      <ImageHotSpot
        title={QuestionData[currentQuestionIndex].questionText}
        hotspots={[
          {
            top: "1.80%",
            left: "33.04%",
            id: "Mons pubis",
            onClick: () => openModal("Mons pubis"),
          },
          {
            top: "36.04%",
            left: "15.18%",
            id: "Outer labia",
            onClick: () => openModal("Outer labia"),
          },
          {
            top: "48.20%",
            left: "49.11%",
            id: "Clitoris",
            onClick: () => openModal("Clitoris"),
          },
          {
            top: "54.95%",
            left: "46.43%",
            id: "Urethra",
            onClick: () => openModal("Urethra"),
          },
          {
            top: "66.22%",
            left: "62.50%",
            id: "Inner labia",
            onClick: () => openModal("Inner labia"),
          },
          {
            top: "73.42%",
            left: "45.54%",
            id: "Vagina",
            onClick: () => openModal("Vagina"),
          },
          {
            top: "79.28%",
            left: "50.00%",
            id: "Hymen",
            onClick: () => openModal("Hymen"),
          },
          {
            top: "102.70%",
            left: "48.21%",
            id: "Perenium",
            onClick: () => openModal("Perenium"),
          },
        ]}
      />
    </Layout>
  )
}

import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import HotSpotBeacon from "../form/HostSpotBeacon"
import Vulva from "../../images/vulvas/vulva-1.png"
import { useViewableHeight } from "../../hooks/useViewableHeight"

const Container = styled.div`
  display: grid;
  animation: fadeIn 0.2s linear;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
const Title = styled.h2`
  padding: 0 24px;
  text-align: center;
  color: ${props => props.theme.shade[10]};
  font-size: 1.22rem;
  @media(min-width: 375px) {
      font-size: 1.44rem;
  }
`
const ImageContainer = styled.div`
  width: ${props => (props.viewableHeight) * 0.5045045045}px;
  height: ${props => (props.viewableHeight)}px;
  position: relative;
  background: url(${Vulva}) no-repeat top center;
  background-size: contain;
  margin: 0 auto;
`

export default function ImageHotspot({ hotspots, title }) {
  const viewableHeight = useViewableHeight()

  return (
    <Container>
      <Title>{title}</Title>
      <ImageContainer viewableHeight={viewableHeight - 160}>
        {hotspots.map((hotspot, i) => (
          <HotSpotBeacon
            key={"hotspot" + i}
            top={hotspot.top}
            left={hotspot.left}
            onClick={hotspot.onClick}
            id={hotspot.id}
          />
        ))}
      </ImageContainer>
    </Container>
  )
}

ImageHotspot.propTypes = {
  hotSpots: PropTypes.object,
  title: PropTypes.string,
}
